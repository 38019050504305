'use client';

import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

export default function CouponBannerDefault() {
  return (
    <div
      id="coupon-banner"
      className={styles.couponWrapper}
      onClick={() => sendLog('ui_coupon_banner_trigger_clicked')}
    >
      Use coupon<span className={styles.couponCode}>FREEMAGNET</span>for a FREE
      4x6 Magnet!
    </div>
  );
}
