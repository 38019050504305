import Image from 'next/image';
import Link from 'next/link';

import toast from '@/components/LazyToaster';
import { sendLog } from '@/services/logs';
import copyToClipboard from '@/utils/copyToClipboard';
import getBasePath from '@/utils/getBasePath';

import styles from './styles.module.scss';

const coupons = [
  {
    code: 'FREECARD',
    title: 'Double Sided 5x7 Cards',
    subtitle: 'Set of 2 Standard - worth $9.99',
    image: `${getBasePath()}/images/homepage/best-sellers/best-seller-card-christmas-tree.webp`,
    url: '/catalogs/cards/categories/e9560962-7581-44de-af8d-5a979ade999a',
  },
  {
    code: 'FREEBIGPRINT',
    title: '8x10 Photo Print',
    subtitle: 'Premium Quality - worth $5.49',
    image: `${getBasePath()}/images/productDescriptions/prints/previews/prints-8x10.webp`,
    url: '/product/prints?name=8x10',
  },
  {
    code: 'FREEMAGNET',
    title: '4x6 Framed Magnet',
    subtitle: 'Perfect Gift - worth $9.99',
    image: `${getBasePath()}/images/homepage/best-sellers/best-seller-magnet-4x6.webp`,
    url: '/product/magnets?name=4x6',
  },
];

const legalLines =
  'Offer ends 12/31/24 at 11:59 pm EST. Offer valid on Double Sided 5x7 Cards (Set of 2 Standard Cards), 8x10 Photo Prints & 4x6 Framed Magnets. Does not include other sized prints or products, or officially-licensed Card designs, or Premium paper upsell. Coupon must be applied at checkout in-app. Coupon cannot be combined with other offers. Limited to the first 500 orders for each code.';

type Props = {
  onClose: () => void;
};

export default function CouponBannerModalDescription({ onClose }: Props) {
  return (
    <div className={styles.pricesPopupContainer}>
      <h2 className={styles.title}>Grab your FREE photo gift!</h2>
      <p className={styles.description}>
        Use these codes at checkout - Limited time only!
      </p>
      <div className={styles.container}>
        {coupons.map((coupon, index) => (
          <Link
            key={`coupon${index + 1}`}
            href={coupon.url}
            onClick={() => {
              sendLog('ui_coupon_dialog_coupon_clicked', {
                ...coupon,
              });
              onClose();
            }}
            className={styles.link}
          >
            <Image
              src={coupon.image}
              alt={coupon.title}
              width={80}
              height={80}
              priority
              className={styles.linkImage}
            />

            <div className={styles.couponInformationsWrapper}>
              <span className={styles.informationDescription}>
                <p className={styles.couponTitle}>{coupon.title}</p>
                <p className={styles.couponSubtitle}>{coupon.subtitle}</p>
              </span>

              <button
                className={styles.couponCodeWrapper}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyToClipboard(coupon.code);
                  onClose();
                  sendLog('ui_coupon_dialog_coupon_code_copied', {
                    ...coupon,
                  });
                  toast.success('Coupon copied!');
                }}
              >
                Copy code:
                <span className={styles.code}>{coupon.code}</span>
              </button>
            </div>
          </Link>
        ))}
      </div>

      <p className={styles.legalDescription}>{legalLines}</p>
    </div>
  );
}
