'use client';

import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Icon from '@/components/Icon';
import SendLogOnViewWrapper from '@/components/SendLogOnViewWrapper';
import { sendLog } from '@/services/logs';

import CouponBannerModalDescription from './CouponBannerModalDescription';

import styles from './styles.module.scss';

export default function CouponBannerModal() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={toggleDialog}>
      <Dialog.Trigger
        id="coupon-banner"
        className={styles.couponTriggerWrapper}
        onClick={() => {
          sendLog('ui_coupon_banner_trigger_clicked');
        }}
      >
        <div className={styles.couponTrigger}>
          <div className={styles.couponGiftIconWrapper}>
            <Icon name="gift" />
          </div>

          <div className={styles.couponTriggerDescription}>
            <p>Get a FREE gift: Magnet, Card, or 8×10 Print</p>
            <div className={styles.couponArrowIconWrapper}>
              <Icon name="arrow" />
            </div>
          </div>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content
          onOpenAutoFocus={(event) => event.preventDefault()}
          onInteractOutside={() => sendLog('ui_coupon_dialog_outside_clicked')}
          className={styles.dialogContent}
        >
          <SendLogOnViewWrapper event="ui_coupon_dialog_viewed">
            <VisuallyHidden>
              <Dialog.Title>Coupon banner informations</Dialog.Title>
              <Dialog.Description>Description</Dialog.Description>
            </VisuallyHidden>
            <Dialog.Close
              onClick={() => sendLog('ui_coupon_dialog_closeBtn_clicked')}
              className={styles.dialogCloseBtnWrapper}
              asChild
            >
              <div className={styles.closeBtnIconWrapper}>
                <Icon name="removeCross" />
              </div>
            </Dialog.Close>
            <CouponBannerModalDescription onClose={handleClose} />
          </SendLogOnViewWrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
